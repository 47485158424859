import $ from 'jquery';

// MENU
const btnMenu = document.querySelector('.toggle-button');
const menu = document.querySelector('.menu-wrap');
const burgerTop = document.querySelector('.menu-bar-top');
const burgerMiddle = document.querySelector('.menu-bar-middle');
const burgerBottom = document.querySelector('.menu-bar-bottom');
//const body = document.querySelector('body');
const toggleMenu = function () {
    menu.classList.toggle('show');
    burgerTop.classList.toggle('open-top');
    burgerMiddle.classList.toggle('open-middle');
    burgerBottom.classList.toggle('open-bottom');
    //body.classList.toggle('no-scroll');
}
btnMenu.addEventListener('click', function (e) {
    e.stopPropagation();
    toggleMenu();

});
document.addEventListener('click', function (e) {
    const target = e.target;
    const its_menu = target == menu || menu.contains(target);
    const its_btnMenu = target == btnMenu;
    const menu_is_active = menu.classList.contains('show');

    if (!its_menu && !its_btnMenu && menu_is_active) {
        toggleMenu();
    }
});

$(document).ready(function () {
    $('#slideToggle').click(function () {
        $('#dropdown-content').slideToggle('slow');
        $('.submenu-arrow').toggleClass('rotate');
    });
});

$(function () {
    $('.nav-sidebar a').each(function () {
        var location = window.location.href;
        var link = this.href;
        if (location == link) {
            $(this).addClass('active');
        }
    });
});


// OWL Carousel Home Page
$(document).ready(function () {
    setTimeout(function () {
        $('aside').addClass('test');
    }, 5000)
    $('.clients-section-logos-top').owlCarousel({
        loop: false,
        nav: false,
        dots: false,
        margin: 30,
        responsive: {
            0: {
                items: 2,
            },
            640: {
                items: 6,
            }
        }
    });
    $('.clients-section-logos-bottom').owlCarousel({
        loop: false,
        nav: false,
        dots: false,
        items: 6,
        margin: 30,
        responsive: {
            0: {
                items: 2,
            },
            640: {
                items: 6,
            }
        }
    });
});

var insightsSwiper = new Swiper('.insights-section-carousel', {
    loop: true,
    slidesPerView: 1.5,
    spaceBetween: 16,
    breakpoints: {
        950: {
            slidesPerView: 2.5,
        }
    }
});

var caseSwiper = new Swiper('.case-studies-section-carousel', {
    loop: true,
    slidesPerView: 1.5,
    spaceBetween: 16,
    breakpoints: {
        950: {
            slidesPerView: 2.5,
        }
    }
});

$( function() {
    $( "#accordion" ).accordion({
        active: false,
        collapsible: true,
        header: '.career_title', 
    });
});



// (function () {
//     var touch = document.querySelector('.footer-section-contact-forms-touch');
//     var help = document.querySelector('.footer-section-contact-forms-help');


//     var observer = new IntersectionObserver(entries => {
//       entries.forEach(entry => {
//         if (typeof getCurrentAnimationPreference === 'function' && !getCurrentAnimationPreference()) {
//           return;
//         }

//         if (entry.isIntersecting) {
//           entry.target.classList.add('touch-animation');
//           entry.target.classList.add('help-animation');
//         }
//       });
//     });

//     observer.observe(touch);
//     observer.observe(help);
//   })();
(function () {
    var animation = document.querySelector('.footer');

    var observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
            var touch = entry.target.querySelector('.footer-section-contact-forms-touch');
            var help = entry.target.querySelector('.footer-section-contact-forms-help');
            var subscribe = entry.target.querySelector('.footer-section-contact-sub-subscribe');
            var email = entry.target.querySelector('.footer-section-contact-sub-email');
            var social = entry.target.querySelector('.footer-section-contact-icons');
            var site = entry.target.querySelector('.footer-section-policy-site');
            var policyPage = entry.target.querySelector('.policy-page');
            var cookie = entry.target.querySelector('.footer-section-policy-block-cookie');
            var author = entry.target.querySelector('.footer-section-policy-block-author');
            if (typeof getCurrentAnimationPreference === 'function' && !getCurrentAnimationPreference()) {
                return;
            }

            if (entry.isIntersecting) {
                touch.classList.add('touch-animation');
                help.classList.add('help-animation');
                subscribe.classList.add('subscribe-animation');
                email.classList.add('email-animation');
                social.classList.add('social-animation');
                site.classList.add('site-animation');
                policyPage.classList.add('policyPage-animation');
                cookie.classList.add('cookie-animation');
                author.classList.add('author-animation');
                return;
            }
        });
    });

    observer.observe(animation);
})();