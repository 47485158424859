$(document).ready(function(){

    //Case studies
    var itemsNumber = 6, $items, pages = 1, current = 1;
    function makePages(){
        $items = $("div.blog_insiqhts article:visible");
        pages = Math.ceil($items.length / itemsNumber);
        $("#pages").empty();
        for(var p=1;p<=pages;p++){
            $("#pages").append($('<a href="#" data-scroll="category">'+p+'</a>'));
        }
        showPage(1);
    }
    function showPage(page){
        $items.hide().slice((page - 1) * itemsNumber, page * itemsNumber).show();
        current = page;
        $("div.ctrl-nav-ins a").show();
        if(current == 1){
            $("div.ctrl-nav-ins a").removeClass('not_post');
            $("div.ctrl-nav-ins a:first").addClass('not_post');
        }else if(current == pages){
            $("div.ctrl-nav-ins a").removeClass('not_post');
            $("div.ctrl-nav-ins a:last").addClass('not_post');
        }
    }
    makePages();

    $("div.ctrl-nav-ins").on('click', 'a', function(){
        var action = $(this).text();
        if(action == 'Previous'){
            current--;

            if(current <= 1){
                current = 1;
            }else if(current >= pages){
                current = pages;
            }

            $('.ctrl-nav-ins>span>a').removeClass('active_current')
            $('.ctrl-nav-ins>span>a:nth-of-type('+ current+ ')').addClass('active_current')
        }else if(action == 'Next'){
            current++;

            if(current <= 1){
                current = 1;
            }else if(current >= pages){
                current = pages;
            }
            $('#pages a').removeClass('active_current')
            $('.ctrl-nav-ins>span>a:nth-of-type('+ current+ ')').addClass('active_current')

        }else if(+action > 0){
            current = +action;
        }
        if(current <= 1){
            current = 1;
        }else if(current >= pages){
            current = pages;
        }
        showPage(current);
    });

    $('#pages').on('click' , 'a' , function (){
        $('#pages>a').removeClass('active_current')
        $(this).addClass('active_current')
    })

    // $('div.filter_insiqhts').on('click', 'a', function(e){
    //     e.preventDefault();

    //     $('.category ul li a').removeClass('active');
    //     $(this).addClass('active');

    //     var selector = $(this).attr('href').replace("#",".");

    //     let all_art = $("div.blog_insiqhts article");
    //     let all_without_art = $("div.blog_insiqhts article").not(selector);

    //     if(selector == '.all'){
    //         $("div.blog_insiqhts article").show()
    //         $('.ctrl-nav-ins span>a:first-of-type').addClass('active_current');
    //         if(all_without_art.length > 7){
    //             $('.ctrl-nav-ins').css('display', 'block')
    //         }
    //     }else {
    //         if((all_art.length - all_without_art.length) < 6){
    //             $('.ctrl-nav-ins').css('display', 'none')
    //         }else{
    //             $('.ctrl-nav-ins').css('display', 'block')
    //         }
    //         $("div.blog_insiqhts article").show().not(selector).hide();
    //     }
    //     makePages();
    // });
    $('.ctrl-nav-ins span>a:first-of-type').addClass('active_current');
});