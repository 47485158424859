

// const listButton = document.querySelectorAll('.member-full');
// const openButton = document.querySelectorAll('.member-demo');

// for( let i = 0; i < openButton.length; i++) {
//     openButton[i].addEventListener('click', function () {
//         for (let j = 0; j < listButton.length; j++) {
//             listButton[j].classList.remove('show-member');
//         };
//         listButton[i].classList.add('show-member')
//         setTimeout ( function(){
//             listButton[i].classList.add('show-opacity')
//         }, 20)
//     })
// }

const closeButton = document.querySelectorAll('.close-button');

for( let i = 0; i < closeButton.length; i++) {
    closeButton[i].addEventListener('click', function () {
        for (let j = 0; j < listButton.length; j++) {
            listButton[j].classList.remove('show-opacity');
            setTimeout ( function(){
                listButton[i].classList.remove('show-member')
            }, 300)
        };
    })
}



// const listButton = document.querySelectorAll('.menuMobile');
// const openButton = document.querySelectorAll('.btn-mobile');

// for( let i = 0; i < openButton.length; i++) {
//     openButton[i].addEventListener('click', function () {
//         for (let j = 0; j < listButton.length; j++) {
//             listButton[j].classList.remove('current');
//         };
//         listButton[i].classList.add('current')
//     })
// }
// const closeButton = document.querySelectorAll('.close-button');
// for( let i = 0; i < closeButton.length; i++) {
//     closeButton[i].addEventListener('click', function () {
//         for (let j = 0; j < listButton.length; j++) {
//             listButton[j].classList.remove('current');
//         };
//     })
// }
// listButton.addEventListener("click", function(){
//   openButton.classList.toggle("current");
// }, false);
// listButton.addEventListener("click", function(){
//     openButton.classList.toggle("show-member");
//   }, false);



  

