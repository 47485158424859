$('.link-block__form').on('click', function (){
    $('.search').toggleClass('active');
})

//Hide search window
$('.search__close').on('click', function (){
   $('.search').removeClass('active');
});

$(document).mouseup(function(e)
{
    var container = $(".search.active");
    if (!container.is(e.target) && container.has(e.target).length === 0)
    {
        container.removeClass('active');
    }
});


$('.search__form input').on('input', function () {
    setTimeout(function() {
        let data_input = $('.search__form input').val();
        if(data_input == ''){
            $('.results__count').hide();
        }else{
            $('.results__count span').html(data_input);
            $('.results__count').show();
        }
    }, 500);

})


let url_service = window.location.href;
console.log(url_service);

$(".other-services-blocks a[data-url='" + url_service + "']").addClass('active current_page');

if ($(window).width() > 1025) {
    $('.other-services-blocks a').mouseover( function (){
        $('.other-services-blocks a').removeClass('active');
        $(this).addClass('active');
    });

    $('.other-services-blocks a').mouseout( function (){
        $('.other-services-blocks a').removeClass('active');
        $('.other-services-blocks a.current_page').addClass('active');
    });

}


AOS.init({
    once: true,
});