$(document).ready(function(){

    if($('.article__second').height() > 350){
        $('.article').addClass('article__with_line');
    }

    $('.disabled').on('click', function (e){
       e.preventDefault();
    })

    //Case studies
    var itemsNumber = 6, $items, pages = 1, current = 1;
    function makePages(){
        $items = $("div.blog_case_studies article:visible");
        pages = Math.ceil($items.length / itemsNumber);
        $("#pages_case").empty();
        for(var p=1;p<=pages;p++){
            $("#pages_case").append($('<a href="#" data-scroll="category">'+p+'</a>'));
            console.log(p)
        }
        showPage(1);
    }
    function showPage(page){
        $items.hide().slice((page - 1) * itemsNumber, page * itemsNumber).show();
        current = page;
        $("div.ctrl-nav a").show();
        if(current == 1){
            $("div.ctrl-nav a").removeClass('not_post');
            $("div.ctrl-nav a:first").addClass('not_post');
        }else if(current == pages){
            $("div.ctrl-nav a").removeClass('not_post');
            $("div.ctrl-nav a:last").addClass('not_post');
        }
    }
    makePages();

    $("div.ctrl-nav").on('click', 'a', function(){
        var action = $(this).text();
        if(action == 'Previous'){
            current--;

            if(current <= 1){
                current = 1;
            }else if(current >= pages){
                current = pages;
            }

            $('.ctrl-nav>span>a').removeClass('active_current')
            $('.ctrl-nav>span>a:nth-of-type('+ current+ ')').addClass('active_current')
        }else if(action == 'Next'){
            current++;

            if(current <= 1){
                current = 1;
            }else if(current >= pages){
                current = pages;
            }
            $('#pages_case a').removeClass('active_current')
            $('.ctrl-nav>span>a:nth-of-type('+ current+ ')').addClass('active_current')

        }else if(+action > 0){
            current = +action;
        }
        if(current <= 1){
            current = 1;
        }else if(current >= pages){
            current = pages;
        }
        showPage(current);
    });

    $('#pages_case').on('click' , 'a' , function (){
        $('#pages_case>a').removeClass('active_current')
        $(this).addClass('active_current')
    })

    // $('div.filter').on('click', 'a', function(e){
    //     e.preventDefault();

    //     $('.category ul li a').removeClass('active');
    //     $(this).addClass('active');

    //     var selector = $(this).attr('href').replace("#",".");

    //     let all_art = $("div.blog_case_studies article");
    //     let all_without_art = $("div.blog_case_studies article").not(selector);

    //     if(selector == '.all'){
    //         $("div.blog_case_studies article").show()
    //         $('.ctrl-nav span>a:first-of-type').addClass('active_current');
    //         if(all_without_art.length > 7){
    //             $('.ctrl-nav').css('display', 'block')
    //         }
    //     }else {
    //         if((all_art.length - all_without_art.length) < 6){
    //             $('.ctrl-nav').css('display', 'none')
    //         }else{
    //             $('.ctrl-nav').css('display', 'block')
    //         }
    //         $("div.blog_case_studies article").show().not(selector).hide();
    //     }
    //     makePages();
    // });
    $('.ctrl-nav span>a:first-of-type').addClass('active_current');



    //Scroll to Start article section
    $('.ctrl-nav').on('click','a:not(.not_post)',function(event){
        event.preventDefault;
        var body = $("html, body"),
            link = $(this).attr('data-scroll');
        body.stop().animate({scrollTop: $('.'+link).offset().top}, '500', 'swing', function() {
        });
        return false;
    });
    $('.category .ctrl-nav-ins').on('click','a:not(.not_post)',function(event){
        event.preventDefault;
        var body = $("html, body"),
            link = $(this).attr('data-scroll');
        body.stop().animate({scrollTop: $('.'+link).offset().top}, '500', 'swing', function() { });
        return false;
    });


    $('.number_phone').on('input', function(ev) {
        var $this = $(this);
        var maxlength = $this.attr('max').length;
        var value = $this.val();
        if (value && value.length >= maxlength) {
            $this.val(value.substr(0, maxlength));
        }
    });


});



