const swiper = new Swiper('.related-cases', {
    loop: true,
    slidesPerView: 1.5,
    spaceBetween: 16,
    breakpoints: {
    950: {
      slidesPerView: 3,
    }
  }
  });
  