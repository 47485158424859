import $ from 'jquery';

$(document).ready(function() {

    $('.checkbox').click(function() {
        var cbIsChecked = $('.checkbox input').prop('checked');
        $('.contact__form form button').prop('disabled', !cbIsChecked);
    });

    $('.newsletter-consent').click(function() {
        var cbIsCheckedbutton = $('#newsletter-consent').prop('checked');
        $('.newsletter-email button').prop('disabled', !cbIsCheckedbutton);
    });
});

